.miscellaneous-pricing-info {
	@include make-miscellaneous-pricing-form;

	&__wrapper {
        &_supplier-info-container {
        	padding-bottom: 1rem;
		}
	
		&_pricing-container{
	        	@include clearfix;
	        	width: 100%;
		}
  
      	.supplier-name{
		    font-size: 1rem;
		    padding-top: 0.25rem;      		
      	}  
  
	      &_total-amount-container {
	    	.total-amount-container {
	        	@include clearfix;
	    		&__total-in-supplier-currency, &__total-in-client-currency {
		        	@include clearfix;
					margin-top: 1rem;
			    	width: 100%;
	    			&_label {
	    				color: $gray;
	    				float: left;
	    				font-size: 1.5rem;
	    			}
	    			&_amount-container {
	    				float: right;
	    				
	    				.total-in-supplier-currency, .total-in-client-currency {
	    					&__currency {
	    						font-size: 1rem;
	    					}
	    					&__value {
	    						color: $brand-color-1;
	    						font-family: $font-family-number;
	    						font-size: 1.5rem;
	    					}
	    				}
	    			}
				}
	    		&__content {
	    			float: right;
	    			width: 30%;
	    			padding-left: 2rem;
	    			
	    			&_label {
	    				color: $brand-color-2;
	    				font-size: 1.5rem;
	    				margin-bottom: -0.5rem;
	    			}
	    			
	    			&_amount-wrapper {
	    				padding-left: .25rem;
	    				
	    				.amount-wrapper {
	    					&__currency {}
	    					&__amount {
	    						color: $brand-color-1;
	    						font-size: 1.5rem;
	    					}
	    				}
	    			}
	    		}
	    	}
	       }
	       				
		}
	
       
       .base-amount-container {
       	@include clearfix;
    	float: left;
    	width: 50%;
    	.base-amount-container { 
	    	&__base-fare,
	    	&__taxes {
   			    width: 50%;
			    padding-right: 1rem;
			    float: left;
				min-height: 4.875rem;
	    	}
    	}
       }
       
        .pricing-container {
	    	float: left;
	    	width: 50%;
        }
  
}