.extra-pricing-info {
	&__header {
		color: $brand-color-2;
		font-size: .875rem;
		&_note{
			color:$brand-color-32;
			padding-left: 1rem;
			&-icon{
				padding-right:.3125rem; 
			}
		}
	}
	
	&__total-amount-container {
		@include clearfix;
		border: 1px solid $gray-lighting;
		cursor: pointer;
		font-size: 1rem;
		height: 34px;
		padding: .25rem;
		&-disabled{
			background-color: $gray-lightest;
			cursor: not-allowed;
		}
		
		&_total-amount-label {
			float: left;
		}
		
		&_total-amount-value-container {
			float: right;
			
			.total-amount-value-container {
				&__amount {
					color: $brand-color-1;
					float: left;
					font-family: $font-family-number;
				}
				
				&__toggle-icon {
					@include make-round-button($brand-color-2, 1.5625rem);
					
					float: left;
					margin-left: .25rem;
					
					>button:focus, :hover {
						font-size: 1rem;
					}
				}
			}
			
			>button {
	    		background-color: $light;
			}
		}
	}
	
	&__content {
		@include clearfix;
		border: 1px solid $gray-lighting;
		border-top: 0;
		
		&_transaction-fee,
		&_markup,
		&_comm-passon,
		&_orc-passon,
		&_plb-passon,
		&_comm-received,
		&_orc-received,
		&_plb-received,
		&_discount-rebate,
		&_service-tax,
		&_input-vat,
		&_input-tax,
		&_consultant-comm,
		&_commission-percentage,
		&_commission-calculated-on,
		&_adjusted-adhoc,
		&_output-vat-percentage {
			float: left;
			min-height: 4.75rem;
			padding: 0 .5rem;
			width: 33.33%;
			
			input {
				font-family: $font-family-number;
			}
			
			.pricing-details-content {
				&__transaction-fee,
				&__markup,
				&__comm-received,
				&__comm-passon,
				&__commission-calculated-on,
				&__orc-received,
				&__orc-passon,
				&__plb-received,
				&__plb-passon,
				&__discount-rebate,
				&__service-tax,
				&__input-vat,
				&__input-tax,
				&__output-vat,
				&__output-vat-percentage,
				&__general-tax,
				&__adjusted-adhoc,
				&__commission-percentage,
				&__consultant-comm {
					&_label {
						color: $brand-color-2;
						font-size: .875rem;
					}
					&_disabled {
						input, select {
							background-color: $gray-lightest;
							cursor: not-allowed;
						}
					}
				}
			}
			
		}
	}
	
	input.general-tax {
		padding: .25rem;
	}
}