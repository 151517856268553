.misc-action-buttons {
	@include clearfix;
	
	float: right;
	padding: .5rem 1rem;
	
	&__checkout, &__pricing-button, &__save-and-retain {
       	@include clearfix;
       	
       	float: left;
 		margin-left: .5rem;   
       	
       	>button {
			@include button-size(0.3125rem, 0 , $font-size-h4 , inherit, 0);
			@include make-button($light, $brand-color-2, $light, darken($brand-color-2, 20)); 
		
			border: none;
			font-weight: 700;
			padding: .25rem .5rem;
			width: 100%;
		}
    }
    
    &__checkout {
    	>button {
    		@include make-button($light, $brand-color-1, $light, darken($brand-color-1, 20));
    	}
    }
}