.miscellaneous-air-ticketing-info {
    border-top: 1px solid $gray-lighting;
    padding-top: .625rem;
    padding-right: 0.5rem;
    &__wrapper{
	    &_tckt-info {
	    	min-height: 4.75rem;
	    	
	    	.gds-pnr,.status,.doi,.invoice-date{
	    		float: left;
			    padding-right: .5rem;
			    width: 25%;
	    	}
	    	
	    	.gds-pnr {
	    		input {
	    			font-family: $font-family-number;
	    		}
	    	}
	    }
	    
	    &_tckt-info, &_pax-tckt-info {
	    	@include clearfix;	
	    }
	    
    }
    
}