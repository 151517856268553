.miscellaneous-air-segment-info {
	border-top: 1px solid $gray-lighting;
	padding-top: .625rem;
	
    &__wrapper {
    	@include clearfix;
        padding-top: .25rem;
    	width: 100%;
        
        &_form-container {
        	margin-top: 1rem;
        	margin-bottom: 0.5rem;
        	
        	.form-container {
        		&__segment-wrapper {
        			margin-bottom: 0.5rem;
				    h4{
				    	margin-bottom: 0;
				    }
				    
				    &_segment-header-container {
						border: 1px solid $gray-lighting;
					}
				    
				    &_segment-header {
			        	@include clearfix;
						background-color: $gray-lighting;
						cursor: pointer;
						font-size: 1.125rem;
						padding: .25rem .5rem;
			        	width: 100%;
			        	color : $gray-darkest;

						.segment-header {
							&__segment-info, &__journey-info {
								float: left;
								width: 100%;
							}
							
							&__journey-info{
								&_title{
									padding-right : 0.75rem;
								}
							}
							
							&__segment-expand-button {
								cursor: pointer;
								float: right;
								width: 2%;
							}
						}
					}
					
        		}
        	}
        }
        
        &_no-of-segments {
			  width: 20%;
			  
        }
    }
}