.misc-other-service-container {
    @include make-miscellaneous-form;
    @include make-input;
    @include hide-number-spinner;

       .footer{
	        &__inclusions, &__exclusions, &__remarks {
		       		float:left;
	        		width: 25%;
	        		padding: 0.25rem 0 0.25rem 1rem;
	        		min-height: 5.5rem;
        	}
			&__action-buttons{
					display: table;
					content: '';
					width: 100%;
				&_container{
					float: right;
				}
				
				&_gv-quantity{
					float: right;
				    width: 50%;
				    content: "";
				    display: table;
				    padding: 0.5rem 1rem;
				    padding-right: 0rem;
				}
			}        	
		}
		.gv-quantity{
			&__label{
				float: left;
			    width: 66%;
			    padding-top: 0.4rem;
			}
			
			&__select{
				float: left;
			    width: 34%;
				
				&_gvQuantity-select{
					height: 30px;
					padding:.325rem 1rem;
				}
			}
		}
   
}

.custom-gv-quantity-popover-class{
	color: $brand-color-2;
	width:100%;
}