.miscellaneous-general-info{
	border-top: 1px solid $gray-lighting;
	padding-top: .625rem;
	
	&__wrapper{
	   	@include clearfix;
        padding: .5rem;
        padding-top: .25rem;
    	width: 100%;
		
		&_service-info-container{
			float: left;
    		width: 100%;
    		padding-bottom:1rem;

			.service-code-list,.service-type-list{
				float: left;
	    		width: 50%;
				
				&__container{
					float: left;
				    padding-right: 1rem;
	  				width: 100%;
					
					&_label{
					    margin-bottom: 0.25rem;
						color: $brand-color-2;
						font-size: $font-size-h4;
						width: 100%;
					}
					
					&_dropdown{
						width: 100%;						
					}
				}
			}
		}
		&_common-info-container{
			@include clearfix;
			display: table;
			content: '';
			width: 100%;
			padding-bottom: 1rem;
			.common-info-container{
				&__status,
				&__supplier-ref-no,
				&__invoice-date{
		    		float: left;
				    padding-right: 1rem;
				    width: 33.33%;
		    	}
				&__supplier-info {
					float:left;
			    	min-height: 4.75rem;
					padding-right: 1rem;
		    		width: 100%;
		    		&_supplier-list,
					&_document-no,
					&_type-no {
						float: left;
						padding-right: 1rem;
						width: 33.33%;
						padding-bottom: 1rem;
					}
				}
		    	&__country-city{
		    		float:left;
			    	min-height: 4.75rem;
					padding-right: 1rem;
    				padding-top: 1rem;
		    		width: 100%;
		    		
		    		&_dropdown {
						.country-city {
							&__country, &__city {
								width: 50%;
				    			float: left;
							}
							&__city {
			    				padding-left: 0.5rem;
							}
						}
					}
		    	}
			}
		}
	}
}