.miscellaneous {
	@include create-search-form;
	@include create-search-form-nav;

	&__search-form {
		min-height: 0;
	}

	&__product-selection {
		width: 35%;
	}
}