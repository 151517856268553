.per-night-price-detail {
	@include clearfix;
	
	background-color: $gray-lighter;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-top: 1px solid $gray-lighting;
   	font-family: $font-family-number;
   	padding-bottom: .5rem;
   	width: 100%;
	
	&__form {
		float: right;
		
		&_header {
			float: left;
			padding-top: .5rem;
			width: 100%;
			
			.price-header{
				&__label-header{
					@include clearfix;
					
					float: right;
					width: 65%;
					
					&_fare, &_tax{
						float: left;
						width: 40%;
					}
				}
			}
		}
		
		&_content {
			@include clearfix;
			
			.price-content{
				&__price-row{
					@include clearfix;
					padding: 0.125rem 0;
					
					&_check-in, &_check-out{
						color: $gray;
						float: left;
						margin-top: 0.5rem;
						width: 15%;
					}
					&_check-in {
						padding-left: .5rem;
					}
					&_fare, &_tax{
						float: left;
						width: 25%;
					}
					&_tax{
						padding-left:0.5rem;
					}
					&_separator {
						float: left;
						font-size: 1rem;
						margin-top: .25rem;
						width: 5%;
					}
				}
			}
		}
	}


}