.misc-hotel-container {
    @include make-miscellaneous-form;
    @include make-input;
    @include hide-number-spinner;
    
    .footer {
        &__remarks, &__misc-booking-reason {
	       	float:left;
        	width: 25%;
        	padding: 0.25rem 0 0.25rem 1rem;
        	min-height: 5.5rem;
    	}
		&__action-buttons {
			display: table;
			content: '';
			width: 100%;
		}
		&__error-icon {
            @include clearfix();
            color: $brand-color-1;
            font-size: 1.125rem;
            float: right;
            margin-top: .125rem;
            margin-right: 1.125rem;
        }          	
	}
}
