.miscellaneous-pax-info-header {
    @include make-miscellaneous-header;
    
    &__pax-container {
    	float: right;
    	margin-top: 0.3rem;
    	
    	&_pax-name-content {
	    	float: left;
	    	
    		.pax-name-content {
		    	&__lead-pax {
		    		color: $gray;
		    		font-size: 1rem;
		    		font-family: $font-family-number;

				    &_pax-count-container-with-count {
				  		margin-top: 0;    	    
				    }
		    	}
		    	
		    	&__more-pax {
		    		color: $brand-color-2;
		    		float: right;
		   			font-family: $font-family-number;
		    	}
    		}
    	}

	    &_error-icon{
		    color: $brand-color-1;
			float: left;
			font-size: 1.125rem;
			margin-left: .5rem;
		}
    }
    
}