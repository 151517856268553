@mixin make-miscellaneous-header_private {
	@include clearfix;
	width: 100%;
	
	&__heading {
		color: $brand-color-2;
        font-size: 1.125rem;
        float: left;
        margin-top: 0.125rem;
    }
    
	&__error-icon {
		color: $brand-color-1;
		font-size: 1.125rem;
		float: right;
        margin-top: 0.125rem;
    }
    
    &__row-icon{
  	    float: left;
  	    margin-top: 0.3rem;
  	    width: 1rem;     
    }
}

@mixin  make-miscellaneous-header { 
	@include make-miscellaneous-header_private; 
}