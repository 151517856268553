.segment-info-form {
	@include clearfix;
	
	&__remove-segment {
		color: $brand-color-1;
		min-height: .5rem;
	}
	
	&__add-segment {
		color: $brand-color-2;
	}
	
	&__remove-segment,
	&__add-segment {
		@include clearfix;
		width: 100%;
		
		&_button {
			float: right;
			padding-right: .25rem;
			
			>button {
				background-color: $light;
			    text-decoration: underline;
			}
		}
	}
	
	&__remove-segment {
		&_button {
			@include add-link($brand-color-1);
			padding-top: .25rem;
		}
	}
	
	&__add-segment {
		&_button {
			@include add-link($brand-color-2);
			padding-bottom: .25rem;
		}
	}
	
	&__airline-data,
	&__journey-data {
		@include clearfix;
		width: 100%;
		
		&_heading {
			color: $gray;
			font-size: 1rem;
			border-bottom: 1px solid $gray-lighting;
			margin: 0 .5rem .25rem;
		}
		
		&_airline-name,
		&_airline-pnr,
		&_flight-number,
		&_airline-rbd,
		&_airline-class,
		&_airline-status,
		&_airline-fare-basis,
		&_airline-from-iata,
		&_airline-from-date,
		&_airline-from-time,
		&_airline-from-terminal,
		&_airline-to-iata,
		&_airline-to-date,
		&_airline-to-time,
		&_airline-to-terminal {
			float: left;
		    min-height: 4.75rem;
		    padding: 0 0.25rem;
		    
		}
		
		&_flight-number,
		&_airline-pnr,
		&_airline-fare-basis,
		&_airline-baggage,
		&_airline-to-time,
		&_airline-from-time,
		&_airline-to-terminal,
		&_airline-from-terminal {
			input {
		    	font-family: $font-family-number;
		    }
		}
	}
	
	&__airline-data {
		&_airline-name {
		    padding-left: .5rem;
   			width: 14%;
	    }
	    
	    &_airline-number-separator {
		    float: left;
		    padding-top: 1.75rem;
	    }
	    
	    &_flight-number {
		    width: 10%;
	    }
	    
	    &_airline-status {
		    width: 6%;
	    }
	    
	    &_airline-class,
	    &_airline-pnr {
		    width: 13%;
	    }
	    
	    &_airline-fare-basis {
		    width: 14%;
		}
		
		&_airline-rbd {
		    width: 6%;
		}
		
		&_airline-baggage {
			float: left;
			width: 13%;
			padding: 0 0.25rem;

			.airline-baggage {
				&__label {
					border-bottom: 1px solid $gray-lighting;
					color: $gray;
					margin-bottom: .25rem;
				}
				
				&__baggage-container {
					&_header-wrapper {
						cursor: pointer;
						
						.header-wrapper__label {
						    color: $brand-color-2;
						    font-size: .875rem;
						    margin-bottom: 0;
						}
					}
					
					&_content-wrapper {
						border: 1px solid;
					    border-color: $gray-lighting;
					    padding: .25rem .5rem .25rem;
					    font-size: 1rem;
					    font-family: $font-family-number;
					    
					    .content-wrapper {
					    	&__baggage-details {
					    		@include clearfix;
				    		    padding: .25rem .25rem 0 .25rem;
					    		
					    		&_pax-baggage {
					    			float: left;
					    			margin-bottom: 0.5rem;
					    			
					    			.pax-baggage {
					    				&__label {
					    					color: $gray-dark;
				    					    float: left;
										    font-size: .825rem;
										    line-height: 2;
    										text-transform: uppercase;
										    width: 25%;
					    				}
					    				&__baggage-input {
			    							float: right;
    										min-height: 40px;
    										width: 65%;
    										
    										input {
    											padding: .25rem;
												width: 50%;
												margin-left: 2.5rem;
    										}
    										
    										.form-field-validation-error {
    											font-size: .7rem;
    										}
					    				}
					    			}
					    		}
					    	}
					    }
					}
				}
			}
		}
		&_airline-baggage-unit {
		    width: 10%;
			float: left;
			padding: 0 0.25rem;
		}
	}
	
	&__journey-data {
		&_airline-from-iata,
		&_airline-to-iata,
		&_airline-from-date,
		&_airline-to-date,
		&_airline-from-time,
		&_airline-from-terminal,
		&_airline-to-time,
		&_airline-to-terminal {
			padding: 0 0.25rem;
		    width: 25%;
		}
		&_airline-from-iata,&_airline-to-iata, {
			padding-left: .5rem;
		}
	}
	
    
    
}