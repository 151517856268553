@mixin make-miscellaneous-form_private {
    @include create-main-wrapper;
    &_wrapper {
        margin-top: 1rem;
    }
    &__form-heading {
    	background-color: $light;
        box-shadow: 0 0 15px rgba(0,0,0,.25);
        color: $brand-color-2;
        font-size: 1.5rem;
        margin-bottom: .5rem;
        padding: 0 1rem 0 1rem;
        &_home-link{
        	color: $brand-color-2;
       	    cursor: pointer;
       	    &:hover{
       	    	text-decoration: underline;
       	    }
        }
    }
    &__form-container {
        @include clearfix;
     	   width: 100%;
        &_accordion-card {
            background-color: $light;
            box-shadow: 0 0 15px rgba(0,0,0,.25);
            margin-bottom: .5rem;
    		padding: 0.5rem 1rem 0.125rem 1rem;
            a {
                color: $dark-gray;
                text-decoration: none;
            }
            a:focus,
            a:hover {
                outline: none;
            }
        }
        &_footer{
        	@include clearfix;
        	background-color: $light;
       	 	box-shadow: 0 0 15px rgba(0,0,0,.25);
        	margin-bottom: 1rem;
        	padding-right: 1rem;
	        .footer{
		        &__remarks {
		        	width: 25%;
		        	padding: 0.25rem 0 0.25rem 1rem;
		        }
		        &__action-buttons{
 		        	&_misc-button-container, &_misc-emd-check-box {
 		        		width:50%;
 		        	}
 		        	&_misc-button-container {
 		        		float:right;
 		        	} 
 		        	&_misc-emd-check-box {
 		        		float:left;
 		        		padding-left: 1rem;
 		        		.checkbox-component {
 		        			font-size:1rem;
 		        		}
 		        		.emd-check-box-container, .emd-check-box-label{
 		        			float:left;
 		        		}
 		        		.emd-check-box-label{
 		        			width:50%;
 		        			padding-top:.15rem;
 		        			padding-left:.20rem;
 		        		}
 		        	}
 		        }
	        }
        }
        
    }
    
    label {
	    color: $sf-label-color;
	    font-size: 0.875rem;
	    margin-bottom: 0;
	    width: 100%;
  	}
  	
  	button:focus {
		outline: none;
	}
	
	.form-field-validation-error {
		margin-bottom: 0;
		width: 100%;
		color: #ec2227;
	}
	
}

@mixin add-link_private($color) {

	>button {
   		background-color: $light;
		color: $color;
		text-decoration: underline;
	}
	
	>button:focus, :hover {
		background-color: $color;
		color: $light;
	    text-decoration: none;
	}
}
@mixin delete-icon_private {

	>button {
   		background-color: $light;
	}
	>button:focus, :hover {
   		>span {
   			color: $brand-color-1;
		}
	}
	
	.remove-button {
		&__icon {
			color: $gray-light;
			font-size: 1.25rem;
		}
	}
}
@mixin add-toggle-button_private {
	&_toggle-icon {
		background-color: $gray-lighting;
		border-radius: 4px;
		color: $light;
	}
	&_toggle-icon:focus,
	&_toggle-icon:hover {
		background-color: $gray;
	}
}
@mixin make-round-button_private($color, $button-size) {

	>button {
   		background-color: $light;
		color: $color;
		height: $button-size;
		width: $button-size;
	}
	
	>button:focus, :hover {
		background-color: $color;
		border-radius: 100%;
		color: $light;
		font-size: .75rem;
	}
}

@mixin  make-miscellaneous-form { 
	@include make-miscellaneous-form_private; 
}
@mixin  add-link($color) { 
	@include add-link_private($color); 
}
@mixin  delete-icon { 
	@include delete-icon_private; 
}
@mixin  add-toggle-button { 
	@include add-toggle-button_private; 
}
@mixin  make-round-button($color, $button-size) { 
	@include make-round-button_private($color, $button-size); 
}