.room-price-info {
	&__room-price-form {
	
		&_header {
			@include clearfix;
			background-color: $gray-lighter;
			border-bottom: 1px solid $gray-lighting;
			border-top-left-radius: 4px;
    		border-top-right-radius: 4px;
			padding: 0 0.5rem;
			
			.room-price-form-header {
				&__room-type {
					color: $gray;
					float: left;
					font-size: 1.5rem;
				}
				&__total-amount {
					float: right;
					
					&_currency {
		    			font-size: 1rem;
		    		}
		    		&_value {
				    	color: $brand-color-1;
				    	font-family: $font-family-number;
						font-size: 1.5rem;
		    		}
				}
			}
		}
		
		&_price-detail {
			margin-top: .5rem;
		}
		
		&_content {
			@include clearfix;
			padding: .5rem;
			position: relative;
			
			.room-price-form-content {
				&__room-category, &__meal-plan, &__room-count, &__base-fare, &__tax-amount, &__adult-count, &__child-count{
					float: left;
					width: 20%;
					padding-left: .5rem;
					input {
						font-family: $font-family-number;
					}
				}
				&__room-category, &__meal-plan, &__adult-count, &__child-count{
					width: 25%;
					min-height: 4.75rem;
				}
				&__base-fare, &__tax-amount {
					width: 18%;
				}
				&__add-price{
					float: left;
				  	font-size: .75rem;
				    padding-left: .25rem;
				    width: 15%;
				    
				    &_toggle-icon {
		    			background-color: $light;
				    	border: 1px solid $gray-lighting;
				    	border-radius: 4px;
				    	color: $gray-dark;
				    	padding: 0;
				    	margin-top: 4px;
			    		width: 100%;
				    	
				    	.price-button {
					    	@include clearfix;
					    	
					    	&__total-toggle-icon, &__per-night-toggle-icon {
					    		float: left;
					    		padding: .25rem;
					    		width: 50%;
					    	}
					    	
					    	&__total-toggle-icon {
					    		border-top-left-radius: 4px;
					    		border-bottom-left-radius: 4px;
					    	}
					    	&__per-night-toggle-icon {
					    		border-top-right-radius: 4px;
					    		border-bottom-right-radius: 4px;
					    	}
					    	
				    		&__toggle-icon-active {
				    			background-color: $brand-color-2;
				    			color: $light;
				    			font-weight: 700;
				    		}
				    	}
				    }
				    
				    &_toggle-icon:focus, :hover {
				    	border-color: $brand-color-2;
				    }
				}
				 &__add-room {
					@include make-round-button($brand-color-2, 30px); 
					float: right;
					font-size: 1rem;
    				margin-left: .25rem;
    				margin-top: 1.5rem;
    				
				}
				&__remove {
					@include delete-icon;
					float: left;
					margin-top: 1.35rem;
				    padding-left: 0.75rem;
					.remove-button {
						&__icon {
							font-size: 1.75rem;
						}
					}
				}
			}
		}

	}
	
}