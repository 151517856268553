.misc-air-pax-ticketing-info{
	
	&__pax-ticket-details,&__pax-reissue-details{
		width:100%;
		float:left;
	}
	&__pax-reissue-details {
		border: 1px solid $gray-lighting;
    	background-color: $body-bg;
    	margin-bottom: .5rem;
	}
	
	&__pax-ticket-details {
		min-height: 4rem;
	
		&_pax-name,&_tckt-num,&_conj-tckt-no{
		    float: left;
		    padding-right: .5rem;
		    width: 25%;
		}
		&_reissue-checkbox {
		    float: left;
		    padding-right: .5rem;
		    width: 5%;
		}
		&_currency-rate{
			float: left;
		    padding-right: .5rem;
		    width: 15%;
		}
		&_pax-name{
			padding-top: 1rem;
			color: $gray;
			font-size: 1rem;
			
			.pax-title,.pax-fName,.pax-lName{
		        margin-bottom: 0;
			    width: 100%;
			}
		}
		&_tckt-num, &_conj-tckt-no , &_currency-rate {
			input {
				font-family: $font-family-number;
			}
		}
	}
}