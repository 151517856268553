.misc-reissue-info{
	&__old-tckt-no,&__org-tckt-no,&__conj-tckt-no,&__original-iata,&__original-doi,&__original-poi{
	    float: left;
	    min-height: 4.75rem;
	    padding: 0 .5rem;
	    width: 25%;
	}
	
	&__old-tckt-no,
	&__org-tckt-no,
	&__conj-tckt-no {
		font-family: $font-family-number;
	}
}