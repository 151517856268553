.miscellaneous-pricing-info-header {
    @include make-miscellaneous-header;
    .currency{
		font-size: 1rem;
	}
	.amount{
		font-size: 1.5rem;
		font-family: $font-family-number;
	}
    
}