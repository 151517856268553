@use "sass:math";

@mixin create-search-form-nav_private {
&__search-nav {
  @include clearfix();
  background-color: $sf-nav-bgcolor;
}

&__search-nav_list {
   li {
    float: left;
    background-color: $sf-nav-list-bgcolor;
    border-right: 2px solid;
    border-color: $sf-list-border-color;
     a {
      padding: math.div($base-padding, 3) math.div($base-padding, 3);
      display: block;
      color: $sf-nav-list-link-color;
      text-transform: uppercase;

      &:hover {
        background-color: $sf-nav-list-link-bgcolor;
        color: $sf-nav-list-link-hover-color;
        text-decoration: none;
      }
    }

  }
  .active {
    background-color: $sf-nav-list-link-bgcolor;
    a {
      color: $sf-nav-list-link-color;
    }
  }
  @media (max-width: $screen-sm-max) {
      display: none;
  }
}
}

@mixin  create-search-form-nav { 
	@include create-search-form-nav_private; 
}