.hotel-room-pricing-info{
	@include make-miscellaneous-pricing-form;
	
    &__wrapper {
        &_supplier-info-container {
	    	margin-bottom: 1rem;
    		width: 100%;
	    	
        	.supplier-info-container {
        		&__supplier-list, &__room-list {
        			width: 20%;
        		}
        		
        		&__supplier-currency {
        			width: 10%;
        		}
        		&__supplier-ref-no {
        			width: 20%;
        			
        			input {
        				font-family: $font-family-number;

        			}
        		}
        	}
        }
        &_room-info-container {
	    	border: 1px solid $gray-lighting;
	    	border-radius: 4px;
	    	float: left;
	    	margin-bottom: 1rem;
        }
        &_extra-price-info-container {
	    	float: left;
	    	width: 100%;
        }
    }
}