.total-pricing-info-currency-details-container {
	border: 1px solid $gray-lighting;
	&__total-in-supplier-currency, &__total-in-client-currency, &__output-taxes,&__third-party-comm-container,
	&__adjust-adhoc-markup,
	&__supplier-payable-amount,
	&__final-invoice-amount,
	&__third-party-commission,
	&__actual_earning,
	&__consult-commission {
		border: 1px solid $gray-lighting;
		border-bottom: 0;
		@include clearfix;
		width: 100%;
		padding: 0.5rem;
		padding-bottom: .925rem;
		&_label {
			color: $gray;
			float: left;
			font-size: 1.125rem;
			width:20%;
			text-transform: capitalize;
		}
		&_amount-container {
			width: calc(80% - 1.5rem);
			float: right;
			margin-right: 1.5rem;
			.total-in-supplier-currency, .total-in-client-currency,.currency-with-input-container,.total-with-currency {
							@include clearfix();
							width:100%;
							justify-content: flex-end;
							display: flex;
							position:relative;
							&__currency {
								font-size: 0.75rem;
								float:right;
                                padding-right:.325rem;
                                padding-top:.325rem;
							}
							&__input-currency{
								border: 2px solid $brand-color-22;
								border-right:none;
                                padding: 0.4375rem;
                                
							}
							&__value {
								float:right;
								color: $brand-color-1;
								font-family: $font-family-number;
								font-size: 1.125rem;
								display: flex;
                                align-items: center;
							}
							&__input {
                                border: 1px solid $brand-color-22;
                                input{
									text-align: right;
									font-size: 1.25rem !important;
								}
								&_errors{
									position:absolute;
									bottom:-1rem;
									right:0%;
									color:$brand-color-1;
								}
								&-disabled{
									input,select{
									background-color: $gray-lightest !important;
			                        cursor: not-allowed;
								}
								}
							}
             }
		}
		&:first-child {
			margin-top:1rem;
		}
		&:last-child {
			border-bottom: 1px solid $gray-lighting;
		}
	}
	&__content {
		float: right;
		width: 30%;
		padding-left: 2rem;
	}
}
.tax-detail {
	@include clearfix;
	&__header-dark {
		border-bottom: 1px solid $gray-lighting;
		padding-bottom: 0.5rem;
	}
	&__header{
		@include clearfix;
		width: 100%;
		&_total-amount-label{
			color: $gray;
			float: left;
			font-size: 1.125rem;
		}
		&_price-container{
			float:right;
			.price-container {
				&__currency {
					font-size: 0.75rem;
				}
				&__amount {
					color: $brand-color-1;
					font-family: $font-family-number;
					font-size: 1.125rem;
				}
				&__expand-button {
					float:right;
					font-size:1rem;
					margin:0 0.35rem;
					margin-top: 0.125rem; 
				}
			}
		}
	}
}
.tax-detail-content {
	@include clearfix;
	float:right;
	font-size: .875rem;
	margin-right:1rem;
	width:30%;
	&__headers ,
	&__content {
		@include clearfix;
    	border-bottom: 1px solid $separator-color;
    	padding: 0.5rem;
		
		&_label {
			float: left;
			width: 50%;
			word-wrap: break-word;
		}
		&_amount {
			float: left;
			width: 50%;
			text-align: right;
			font-family: $font-family-number;
		}
	}
	&__headers {
    	color: $gray;
	}
	
	&__content {
    	&:last-child {
	    	border-bottom: none;
    	}
	}
}
.third-party-agent-container{
	@include clearfix();
	width:100%;
	&__agent-selector,&__commision-input{
		@include clearfix();
		width:50%;
		float:left;
	&_title{
		float:right;
		font-size: 1rem;
    padding: 0.3125rem;
    color: $brand-color-28;
    width:40%;
    text-align: right;
	}
	&_value{
		 width:60%;
		float:right;
		input,select{
			font-size: 1.25rem !important;
			text-align: right;
		}
		select{
			width:100% !important;
			text-align: left;
			font-size: .825rem !important;
		}
	}
	}
	
}



